.container {
  display: flex;
  min-width: 100%;
  flex-direction: column;
  > div {
    display: flex;
    align-items: center;
  }
  > div:first-of-type {
    margin-bottom: 20px;
  }
  h4 {
    text-align: left;
    margin: 0px 0px 13px 0px;
  }
  p {
    font-size: 17px;
    font-weight: 800;
  }
}
.carecloudLogo {
  width: 80%;
  height: 80%;
  margin-left: 2px;
}
.auditionCafeLogo {
  width: 82%;
}
.rbiLogo {
  width: 92%;
}
.optimizeLogo {
  width: 65%;
}

.imageCrop {
  align-self: flex-start;
  height: 27px;
  position: relative;
  flex-shrink: 0;
  right: 3px;
  background: #4c98cf;
  overflow: hidden;
  border-radius: 50%;
  align-items: center;
  display: flex;
  width: 28px;
  justify-content: center;
}
.auditionCafeCrop {
  @extend .imageCrop;
  background: #3d4450;
}
.rbiImageCrop {
  @extend .imageCrop;
  background: #fff;
}
.infoContainer {
  display: flex;
  flex-direction: column;
  max-width: 500px;
  margin-bottom: 5px;
  p {
    margin: 1px 3px !important;
  }
  ul {
    margin: 5px 0 0;
    padding-left: 4px;
    color: #fff;
    li {
      font-size: 22px;
      margin-bottom: 20px;
      span {
        font-size: 17px;
      }
    }
    li:last-of-type {
      margin-bottom: 0;
    }
  }
  .subHeader {
    font-size: 12px;
  }
  .year {
    font-weight: 500;
    font-size: 12px;
  }
}
