.container {
  display: flex;
  min-width: 100%;
  flex-direction: column;
  p {
    font-size: 17px;
  }
  div {
    margin-bottom: 8px;
    p:first-of-type {
      font-weight: bold;
    }
  }
  > div:last-of-type {
    margin-bottom: 0;
  }
}
