@import '../../utilities/scss_variables.scss';

.container {
  background: $primary;
  min-height: 100vh;
  width: 100vw;
  display: flex;
  max-width: 100%;
  overflow: hidden;
  p {
    margin: 3px;
  }
  h2 {
    margin-top: 20px;
    color: #fff;
  }
}
.bioInfo h4 {
  color: #fff;
  font-weight: 800;
  text-align: left;
}
.imageContainer {
  @media screen and (max-width: 742px) {
    display: none;
  }
  min-height: 100vh;
  flex: 1;
  min-width: 55%;
  background-image: url('../../images/computer.svg');
  background-size: cover;
}
.textContainer {
  opacity: 0;
  margin: 17px 30px;
  h1 {
    @include fluid-type(320px, 1200px, 42px, 80px);
  }
  h3 {
    @include fluid-type(320px, 1200px, 18px, 28px);
  }
}
.fadeIn {
  animation: fadeIn 1s forwards;
}
.about {
  @media screen and (min-width: 742px) {
    min-width: 760px;
  }
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.portfolioMore {
  @media screen and (max-width: 739px) {
    display: none;
  }
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
  cursor: pointer;
  svg {
    color: #fff;
    font-size: 25px;
  }
}
