// n is number of stars required
@function multiple-box-shadow($n, $screenSize) {
  $value: '#{random($screenSize)}px #{random($screenSize)}px';

  @for $i from 2 through $n {
    $value: '#{$value} , #{random($screenSize)}px #{random($screenSize)}px';
  }

  @return unquote($value);
}

$shadows-small: multiple-box-shadow(700, 2000);
$shadows-medium: multiple-box-shadow(200, 2000);
$shadows-big: multiple-box-shadow(100, 2000);

$shadows-small-widescreen: multiple-box-shadow(700, 3000);
$shadows-medium-widescreen: multiple-box-shadow(200, 3000);
$shadows-big-widescreen: multiple-box-shadow(100, 3000);

.parallax {
  margin-right: auto;
  color: #fff;
  #stars1 {
    width: 1px;
    height: 1px;
    border-radius: 50%;
    background: transparent;
    box-shadow: $shadows-small;
    @media screen and (min-width: 2000px) {
      box-shadow: $shadows-small-widescreen;
    }
    animation: animStar 150s linear infinite;

    &:after {
      content: ' ';
      position: absolute;
      top: 2000px;
      width: 1px;
      height: 1px;
      border-radius: 50%;
      background: transparent;
      box-shadow: $shadows-small;
      @media screen and (min-width: 2000px) {
        box-shadow: $shadows-small-widescreen;
      }
    }
  }

  #stars2 {
    width: 2px;
    height: 2px;
    border-radius: 50%;
    background: transparent;
    box-shadow: $shadows-medium;
    @media screen and (min-width: 2000px) {
      box-shadow: $shadows-medium-widescreen;
    }
    animation: animStar 100s linear infinite;

    &:after {
      content: ' ';
      position: absolute;
      top: 2000px;
      width: 2px;
      height: 2px;
      border-radius: 50%;
      background: transparent;
      box-shadow: $shadows-medium;
      @media screen and (min-width: 2000px) {
        box-shadow: $shadows-medium-widescreen;
      }
    }
  }

  #stars3 {
    width: 3px;
    height: 3px;
    border-radius: 50%;
    background: transparent;
    box-shadow: $shadows-big;
    @media screen and (min-width: 2000px) {
      box-shadow: $shadows-big-widescreen;
    }
    animation: animStar 50s linear infinite;

    &:after {
      content: ' ';
      position: absolute;
      top: 2000px;
      width: 3px;
      height: 3px;
      border-radius: 50%;
      background: transparent;
      box-shadow: $shadows-big;
      @media screen and (min-width: 2000px) {
        box-shadow: $shadows-big-widescreen;
      }
    }
  }

  @keyframes animStar {
    from {
      transform: translateY(-2000px);
    }

    to {
      transform: translateY(0px);
    }
  }
}
