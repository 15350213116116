@import '../../utilities/scss_variables.scss';

.dark {
  background: $dark;
}

.black {
  background: $black;
}

.green {
  background: $green;
}

.red {
  background: $red;
}

.landing {
  background: $landing;
}

@keyframes fadeIn {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(3px);
  }
  100% {
    transform: translateY(0px);
  }
}

.magicIcon {
  height: 40px;
  width: 40px;
  cursor: pointer;
  user-select: none;
  position: absolute;
  right: 20px;
  top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    color: #fff;
    height: 24px;
    width: 24px !important;
    animation: fadeIn 1.25s ease-in-out infinite;
  }
}

.container {
  transition: 0.5s;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  overflow: hidden;
  h1 {
    @include fluid-type(320px, 1200px, 42px, 90px);
  }
  h3 {
    @include fluid-type(320px, 1200px, 20px, 32px);
  }
  h4 {
    color: #fff;
    margin-left: 5px;
    margin: 10px 0px 0px 5px;
    font-weight: 700;
    text-align: center;
    @include fluid-type(320px, 1200px, 17px, 24px);
  }
  hr {
    background: #fff;
    width: 98%;
    height: 1px;
    border: 0;
    margin: 12px auto 12px auto;
  }
}
.textWrapper {
  display: flex;
  flex-direction: column;
  position: absolute;
}
