body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
p {
  color: #fff;
}

h1 {
  font-size: 90px;
  font-family: 'Montserrat';
  font-weight: 700;
  margin: 0px 0px 5px 0px;
}

h2 {
  font-size: 54px;
  font-family: 'Montserrat';
  font-weight: 700;
}

h3 {
  font-size: 32px;
  font-family: 'Montserrat';
  font-weight: 700;
  margin-left: 5px;
  margin: 10px 0px 10px 5px;
}

h4 {
  font-size: 23px;
  font-family: 'Open Sans';
  font-weight: 700;
  margin: 0px 0px 13px 0px;
}

h5 {
  font-size: 20px;
  font-family: 'Open Sans';
  font-weight: 700;
}

p {
  font-size: 18.5px;
  margin: 3px;
  font-family: 'Open Sans';
}
