@import '../../utilities/scss_variables.scss';

.container {
  background: #34495e;
  height: fit-content;
  width: 100vw;
  display: grid;
  max-width: 100%;
  padding: 35px;
  @media screen and (max-width: 739px) {
    padding: 14px;
  }
  cursor: pointer;
  p {
    @include fluid-type(320px, 1200px, 12px, 18.5px);
  }
  h3 {
    margin: 0px 0px 65px 0px;
    text-align: center;
    @include fluid-type(320px, 1200px, 30px, 32px);
  }
}
.carecloudLogo {
  width: 240px;
  align-self: center;
}
.visitBtn {
  margin-top: auto;
}
.overlay {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0);
  transition: 0.5s;
  position: absolute;
  z-index: 10;
  opacity: 0;
  padding: 17px 21px 21px 21px;
  p {
    line-height: 26.5px;
    font-weight: 800;
  }
  div:last-of-type {
    display: flex;
    align-items: center;
    align-self: center;
    opacity: 0;
    transform: translateX(-35px);
  }
  svg {
    color: #fff;
  }
}
.breezeContainer {
  background: #fafafa;
  overflow: hidden;
  display: flex;
  height: 300px;
  width: 100%;
  max-width: 600px;
  border-radius: 7px;
  justify-content: center;
  align-items: center;
  padding: 0px 20px;
  position: relative;
  > img {
    transition: 0.5s;
    height: 70%;
  }
  &:hover > div {
    transition: 0.5s;
    z-index: 10;
    opacity: 1;
    background-color: rgba(0, 0, 0, 0.7);
  }
  &:hover > img {
    transform: scale(1.1);
    transition: 0.7s;
  }
  &:hover > div > div:last-of-type {
    animation: slideIn 0.7s forwards;
  }
}
.goContainer {
  @extend .imgCrop;
  background-color: #fafafa;
}
.auditionContainer {
  @extend .imgCrop;
  img {
    min-width: 418px;
  }
}
.rbiContainer {
  @extend .imgCrop;
  img {
    min-width: min-content;
  }
  width: unset;
}
.imgCrop {
  overflow: hidden;
  display: flex;
  height: 300px;
  width: 100%;
  max-width: 600px;
  justify-content: center;
  align-items: center;
  border-radius: 7px;
  position: relative;
  img {
    width: 100%;
    border-radius: 7px;
    transition: 0.5s;
  }
  &:hover > div {
    opacity: 1;
    transition: 0.5s;
    z-index: 10;
    background-color: rgba(0, 0, 0, 0.7);
  }
  &:hover > div > div:last-of-type {
    animation: slideIn 0.7s forwards;
  }
  &:hover > img {
    transform: scale(1.1);
    transition: 0.7s;
  }
}
.contentContainer {
  display: grid;
  justify-content: space-around;
  align-items: center;
  grid-template-columns: repeat(auto-fit, minMax(331px, 1fr));
  grid-column-gap: 35px;
  grid-row-gap: 35px;
  > div {
    border-radius: 7px;
    height: 370px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
  }
  h4 {
    text-align: center;
  }
}
.safari {
  height: unset;
  p {
    font-size: 15px;
  }
}
@keyframes slideIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
}
