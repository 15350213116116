@import '../../utilities/scss_variables.scss';

.logoContainer {
  display: flex;
  align-self: center;
  color: #fff;
  font-size: 40px;
  width: 92%;
  min-width: 330px;
  justify-content: space-between;
  a {
    height: fit-content;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
    color: inherit;
  }
  h4 {
    color: #fff;
    font-family: 'Open Sans';
    font-weight: 800;
    margin-top: 10px;
    @include fluid-type(320px, 1200px, 12px, 20px);
  }
  svg {
    transition: 0.4s;
    height: 40px;
  }

  @media screen and (max-width: 739px) {
    svg {
      height: 25px;
    }
    gap: 5px;
  }

  svg:hover {
    transform: scale(1.2);
    transition: 0.4s;
  }
}
