.container {
  margin-top: 20px;
  align-self: center;
  display: flex;
  align-items: center;
  position: relative;
  @media screen and (max-width: 739px) {
    > svg:last-of-type {
      position: absolute;
      top: 103.5%;
      left: 65%;
    }
    > svg:first-of-type {
      position: absolute;
      top: 103.5%;
      left: 25%;
    }
    margin-bottom: 80px;
  }
  p {
    color: #fff;
  }
}
.contentContainer {
  align-self: center;
  margin-top: 15px;
  height: fit-content;
  background: rgba(112, 112, 112, 0.15);
  border-radius: 15px;
  overflow: hidden;
  max-width: 605px;
  @media screen and (min-width: 2100px) {
    max-width: 750px;
  }
  @media screen and (max-width: 739px) {
    max-width: 85vw;
  }
}
.menuContainer {
  margin: 0 20px;
}
.arrow {
  opacity: 0.25;
  transition: 0.5s;
  color: #fff;
  font-size: 40px;
  transition: 0.5s;
  &:hover {
    transform: scale(1.1);
    transition: 0.5s;
  }
}
.activeArrow {
  opacity: 1;
  transition: 0.5s;
  cursor: pointer;
}
.slider {
  display: flex;
  transition: 0.7s;
  > div {
    padding: 17px 17px 17px 23px;
  }
}
.menu {
  display: flex;
  justify-content: space-between;
}
.active {
  opacity: unset !important;
}
.menuItem {
  height: 4px;
  width: 17%;
  background-color: #fff;
  opacity: 0.3;
  cursor: pointer;
}
