@import '../../utilities/scss_variables.scss';

.container {
  background: #2c3e50;
  padding: 50px 0;
  height: fit-content;
  width: 100vw;
  display: flex;
  grid-template-columns: repeat(auto-fit, minMax(400px, 1fr));
  max-width: 100%;
  align-items: center;
  flex-direction: column;
  p {
    @include fluid-type(320px, 1200px, 16px, 18.5px);
  }
  h3 {
    margin-bottom: 50px;
  }
}
.rightInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  div {
    display: flex;
    align-items: center;
  }
}
.infoContainer {
  display: flex;
  height: 100px;
  h4 {
    margin-bottom: 5px;
  }
  & div:nth-of-type(2) > div {
    display: flex;
    align-items: center;
    svg {
      color: #fff;
      font-size: 20px;
      margin-right: 5px;
    }
  }
}
.grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minMax(360px, 1fr));
  grid-row-gap: 80px;
  justify-items: center;
  max-width: 100%;
}
.imgCrop {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  margin-right: 17px;
  overflow: hidden;
  img {
    position: relative;
    height: 101.5px;
    width: auto;
  }
}
