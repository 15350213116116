@import '../../../../utilities/scss_variables.scss';

.container {
  display: flex;
  min-width: 100%;
  flex-direction: column;
  p {
    font-size: 17px;
  }
  span:first-of-type {
    font-weight: 800;
  }
  > div:not(:last-of-type) {
    margin-bottom: 10px;
  }
  p {
    margin-left: 13px;
  }
  h4 {
    text-align: left;
    margin: 0px 0px 13px;
  }
}
.contentContainer {
  display: flex;
  align-items: center;
}
.imageCrop {
  height: auto;
  display: flex;
  overflow: hidden;
  border-radius: 50%;
  margin-right: 8px;
  flex-shrink: 0;
}
.image {
  width: 25px;
  height: 25px;
}
.schoolContainer {
  display: flex;
  flex-direction: column;
  > p:last-of-type {
    font-style: italic;
    max-width: 370px;
    font-size: 14px;
    margin-left: 37px;
  }
}
.awardContainer {
  color: #fff;
  margin-left: 38px;
  display: flex;
  svg {
    font-size: 17px;
    margin: 7px 3px 0 0;
  }
  p {
    @include fluid-type(320px, 1200px, 15px, 17px);
  }
  display: flex;
}
